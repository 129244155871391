@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

/* @font-face {
  font-family: 'Calibri';
  src: url('../public/fonts/Calibri-Bold.woff') format('woff2'),
      url('../public/fonts/Calibri-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: 'Calibri';
  src: url('../public/fonts/Calibri.woff2') format('woff2'),
      url('../public/fonts/Calibri.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */



body {
    font-family: 'Inter', sans-serif;
  /* font-family: 'Calibri'; */
  font-size: 15px;
  line-height: 1.3;
  text-align: left;
  font-weight: normal;
  color: #000;
  font-weight: normal;
  background-color: #edece8;

}


*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

img {
  border: none;
  max-width: 100%;
  display: block;
  align-self: flex-start;
}

a,
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.img {
  display: block;
  width: 100%;
  height: auto;
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}
.center-align
{
  text-align:center
}
.right-align
{
  text-align:right
}
.bg-creame
{
  background-color: #edece8;
}
.bg-blue
{
  background-color: #e8eefb;
}
.bg-w
{
  background-color: #fff;
}
   section
   {
      position: relative;
      overflow: hidden;
   }   
   header
   {
      position: fixed;
      z-index: 30;
      height: 80px;
      display: flex;
      width: 100%;
      background-color: rgba(237,236,232,0);
      transition: background 0.7s;
   }
   header.fixed
   {
      background-color: rgba(237,236,232,1);
   }
   header .container
   {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
   #logo
   {
      display: block;
      width: 270px;
      position: relative;
   }
   .img-b
   {
      position:absolute;
      top:0;
      left:0;
      opacity: 0;
      transition: opactiy 0.7s;
   }
   .fixed   .img-b
   {
      opacity: 1;
   }
   .fixed   .img-w
   {
      opacity: 0;
   }
   
   #ul li{
      display: inline-block;
      list-style: none;margin-left: 25px;
   }
   #ul li:first-child
   {
      margin-left: 0;
   }
   #ul li a{
    font-weight: 500;
    transition: all 0.5s;
   }
   #ul li a:hover{
   text-decoration: underline;
   }
   a.contact
   {
      padding: 8px;
      width:100px;
      color: #fff;
      background-color: #2186f2;
      border-radius: 5px;
      border:1px solid #2186f2;
   }
   #ul li a.contact:hover
   {
    text-decoration: none ;
   }
   #contactF{
    display: block;
    margin: 60px auto;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
   }
   #nav-toggle {
    position: absolute;
    right: 20px;
    z-index: 10;
    cursor: pointer;
    width: 38px;
    height: 26px;
    display: none;
}

    #nav-toggle span, #nav-toggle span:after, #nav-toggle span:before {
        cursor: pointer;
        height: 2px;
        width: 100%;
        background: #2186f2;
        position: absolute;
        display: block;
        content: '';
        top: 10px;
        -webkit-transition: all .6s ease-in-out;
        -moz-transition: all .6s ease-in-out;
        -o-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
    }

        #nav-toggle span:before {
            top: -10px;
        }

        #nav-toggle span:after {
            bottom: -10px;
        }

    #nav-toggle.active span {
        background-color: transparent !important;
    }

        #nav-toggle.active span:after, #nav-toggle.active span:before {
            top: 0;
        }

        #nav-toggle.active span:before {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: a rotate(45deg);
        }

        #nav-toggle.active span:after {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: a rotate(-45deg);
        }
#landing
{
  position: relative;
  width:100%;
  height:100vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

   
.l-left,.l-right
{
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #FFF;

}
.hand
{
  position: absolute;
  bottom: 0;
   right: 0;
   width: auto;
   height: 80%;
   display: block;
   transform: translateX(100%);
   opacity: 0;
   max-width: 100%;
   
}
.loaded .hand
{
  transition: transform 2s ,opacity 1.5s ;
  transform: translateX(0%);
  opacity: 1;

}
.svg-holder
{
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% - 237px);
  height: 100%;
  z-index: 0;
  transform: scale(0,1);
  transform-origin: left top;
  opacity: 0;
  background: #2186f2;



}
.loaded .svg-holder
{
  transition: transform 1.5s ,opacity 1.5s ;
  transform: scale(1,1); 
  opacity: 1;
}

.svg-holder svg{
  position: absolute;
  right: -248px;
  /* animation:5s infinite alternate shape-ver linear; */
  width: 250px;
  height: 147%;
  z-index: 3;
  display: block;



}
@keyframes shape-ver {
  0% {
      height: 147%;
    }
  100% {
      height: 200%;
  }
}
#enquire .flex-sb
{
  align-items: flex-start;
}
.flex-sb
{
display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
}
.align-top
{

  align-items: flex-start;

}
.flex-reverse
{
  flex-direction: row-reverse;
}
.flex-s
{
  display: flex;
  justify-content: flex-start;
  align-items: center
  
}

.flex-s img{
  display: block;
  border-radius: 50px;
  height:80px;
  margin-right: 15px;

}
.container
{
  width:1400px;
  margin: auto;
  max-width: calc(100% - 200px);
}
.grid_6
{
  width:50%
}
.grid_4
{
  width:34%
}
.grid_th
{
  width:30%
}
.grid_3
{
  width:calc(25% - 7.5px);
  display: block;


}
.grid_33
{
  width:33.33%
}
.w300
{
  width: 390px;
}
.v_w300
{
  width: calc(100% - 410px);
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 30px 30px 0 0;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  border-radius: 10px;
}
.w500
{
  width: 42em;
  margin: auto;
  max-width: calc(100% - 40px);
  opacity: 0;
  transition: opacity 1s;
  transition-delay: 1.5s;
}

.w500 h3{
  max-width: 20em;
}
.loaded .w500{
  opacity: 1;
}
.ptb150
{
  padding: 100px 0;
}.pt150
{
  padding-top: 100px;
}
h1{
  font-size:60px;
  margin-bottom: 0.5em;
  line-height: 1;
  letter-spacing: -0.05em;
}
h2{
  font-size:42px;
  margin-bottom: 0.5em;
  line-height: 1;
  letter-spacing: -0.05em;
  
  
}
h2{
  color: #2186f2;
  letter-spacing: -0.05em;

}
.blue
{
  color: #2186f2; 
}
h2 span
{
  color: #000;
}
h2 span.sml
{
  font-size: 0.8em;
  display: block;
  font-weight: normal;
  letter-spacing: 0;
  padding-top: 0.2em;
}
h3{
  font-size:21px;
  margin-bottom: 0.4em;
  
}
.b-line
{
  font-size:26px;
  text-align: center;

}
.b-line::after{
  content: '';
  display: block;
  width:300px;
  height: 2px;
  background: #000;
  margin: 70px auto 30px auto;
}
.content
{
  position: relative;
  z-index: 2;

}
.mb10
{
  margin-bottom: 10px;
}
.mb2
{
  margin-bottom: 1.2em;
}

.mb20
{
  margin-bottom: 10px;
}
.royal
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  margin-bottom: 30px;
}
.royal span{
  display: block;
  width:100px
}
.royal span svg{
  display: block;
  width:60px;
  height:auto
}
.royal p{

  width:calc(100% - 100px)
}
.slider
{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-end;

}
.control
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  position: absolute;
  top: 30px;
  z-index: 5;
  right: 0;
}

.step
{
  display: flex;
  width: 16.66%;
  height: 40px;
  text-align: center;
  border: 1px solid #2186f2;
  padding: 0;
  line-height: 1;
  justify-content: center;
  align-items: center;
  color: #2186f2;
  border-right: none;
  
}
.step6
{
 border: none;
 position: relative;
 background: none !important;
 overflow: hidden;

}
.step6 span
{


  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
}

.step6::before{
  z-index: 1;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(45deg, #2186f2, #ff6600d9, #2186f2, #2186f2, #fff, #ff6600d9, #fff, #2186f2);
  background-size: 400%;
  bottom: 0;
  right: 0;
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  
  50% {
    background-position: 400% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}
.step.active{
 background-color: #2186f2;
 color: #fff;
}

.step.active span{
  background-color: #2186f2;
  color: #fff;
 }
.slider-content
{

  display: flex;
  justify-content: flex-end;
  position: absolute;
  background: #fff;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 0.7s;
min-height: 372px;
width: 100%;
flex-wrap: wrap;



}
.slider-content.active{
  position: relative;
  z-index: 2;
  opacity: 1;
}
.slider-img
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 15px 30px 15px 15px;

}
.slider-img img.sml
{
  transform: scale(0.9);
}
.slider-copy  {

  width: 60%;
      padding: 90px 0 30px 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    
}
.slider-img img{
  display: block;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 400px;
  object-fit:contain
}
.exprt{

  position: relative;
  transition: all 0.5s;
  perspective: 1000px;
  margin-bottom: 10px;
}
.exprt:hover
{

  transform: scale(1.02);
}
.inner span, .inner path{
  transition: fill 0.5s,color 0.5s;
}

.inner{
  margin-bottom: 10px;
  border-radius: 10px;
  position:absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: block;
  transition: all .8s;
  transform-style: preserve-3d;
  will-change: transform;
  background-color: #fff;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  
}
.face
{
  position: absolute;    top: 0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

}
.face:hover  span{
  fill: #2186f2 ;
  color: #2186f2;
}
.face:hover  path{
  fill: #2186f2 ;
  
}
.exprt:hover .inner{
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
}
.inner.rotate {
  transform: rotateX(0deg) rotateY(-180deg);

}
.back {
  top: 0;
  left:0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 10px;;
  transform-style: preserve-3d;
  transition:  all .8s;
  transform: translateZ(-1px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  z-index: -1;


}
.rotate .back {
  z-index: 3;
}
.back span{
  color: #000 !important;
}

.icon
{
  display: block;
  width:56px;
  height: auto;
  margin:0 0 20px 0

}
.b-icon
{
  display: block;
  width:105px;
  height: auto;
  margin :0 auto 20px auto
}
.bb-icon{
  display: block;
  width: auto;
  height: 125px;
  margin: 0 auto 20px auto;
}
.copy-r
{
  text-align: center;
  justify-content: center !important;
  transform: translateY(-100%);
}
.w260
{
  width: 262px;
  margin: auto;
  text-align: center;

}
.bg-white
{
  background-color: #fff;
}
.title {
  font-weight: bold;
  font-size: 24px;
  display: block;
  width: 100%;
  text-align: center;
}
.exprt .img{
  position: relative;
  z-index: 0;
  opacity: 0;
}
.reverse{
  padding: 0 20px;
  text-align: center;    
  transform: translateZ(-1px) rotatey(-180deg);

}.arrow,.close
{
  display: block;
  width: 20px;
  height: auto;
  position: absolute;
  bottom: 15px;
  right: 15px;
}.close
{
  right: auto;
  left: 15px;
  width:24px
}

.back span.read-more
{
  display: block;
  line-height: 1;
  padding: 8px 0;
  width: 110px;
  text-align:center;
  border: 1px solid #2186f2;
  border: 1px solid #2186f2;
  color: #2186f2 !important;
  margin: 20px auto 0 auto;
}
#popup,#privacy
{
  position:fixed;
  z-index: 1000;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.7s;
}
#bg-close,#bg-close-d
{
  position:absolute;
  z-index: 1;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

}
#container-p
{
  text-align: left;
  height: calc(100% - 80px);
  overflow-x: scroll;
}
.dot
{
  display: inline-block;
  width: 20px;
  text-align: left;
}
.dot::after{
  content: '';
  width:5px;
  height: 5px;
  display: block;
  border-radius: 5px;
  background-color: #000;
  transform: translateY(-5px);
}
.container-p{
  padding: 60px;

width:1200px;
max-width:100%;
background-color: #fff;
border-radius: 15px;
box-shadow: 0 0 11px rgba(33,33,33,.2);
z-index: 2;
text-align: center;
position: relative;

}
.container-p p{
  margin-bottom: 1em;
}
#closePop,#closeEnqBtn,#closeDis
{
  display: block;
  position: absolute;
  top:10px;
  right: 10px;
  width:24px
}
.logos
{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.logoSp
{
  padding: 45px 0;
}
.logoSp img{
  display: block;
  height: 40px;
}
.logoSp img.ubank
{
  height: 30px;
}
.logoSp img.Stgeorge
{
  height: 50px;
}
.logoSp img.amp
{
  height: 45px;
}
.max600
{
  max-width: 735px;
  margin-left: auto;
  margin-right: auto;
}
.w1000
{
  width: 1000px;
  max-width: 100%;
  margin: auto;
}
.teamHolder
{
  width:45%;
  padding: 45px 50px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: inset 0px 1px 9px 0px rgb(0 0 0 / 80%);

}
.teamHolder img{
  margin-bottom: 10px;
}
#about p
{
font-size: 1.15em;
}
#enquire
{
  padding: 50px 30px;
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  max-width: 100%;
  height: 100%;
  box-shadow: 2px 7px 9px 6px rgb(0 0 0 / 80%);
  z-index: 50;
  border-radius: 20px 0 0 20px;
  transform: translateX(105%);
  transition: transform 0.7s;
  background-color: #edece8;
  overflow-y: scroll;
}
label
{
  display: block;
  margin-bottom: 5px;

}
input, textarea {
  font-size: 16px;
  margin: 0 auto;
  padding: 9px 5px;
  width: 100%;
  color: #000;
  border: 0 none;
  background: transparent;
  appearance: none;
  border-radius: 5px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #fff;
  resize: none;
  font-family: inherit;

}
.inputHolder
{
  width:calc(50% - 10px);
  margin-bottom: 20px;
}
.btn-sub
{
  display: block;
  cursor: pointer;
  width:100px;
  text-align: center;
  color: #fff;
  background-color:#2186f2;
  border-radius: 5px;
  margin: 20px  0 0 0;
 border:1px solid #2186f2;
 transition: all 0.5s;
}
.btn-sub:hover{
  background-color: #fff;
  color: #2186f2;
  margin-bottom: 10px;
}
#closeEnq
{
  position: fixed;
  background-color: rgb(0 0 0 / 60%);
  display: none;
  opacity: 0;
  top:0;
  right: 0;
  width:100%;
   z-index: 40;
  height: 100%;
  transition: opacity 0.7s;
  ;
}
span.false
{
  font-size: 10px;
  color: red;
}
.e-holder
{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #e8eefb;
  padding: 8px 0;
  font-size: 14px;
}
.e-holder svg{
  display: block;
  margin-right: 15px;


}
.team-c
{
      margin: 25px 0;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    padding: 15px 0 0px 0;
}
footer
{
  background-color: rgba(237,236,232,1);
  padding: 30px 0;
}
.footer-logo
{
  width:200px;
  display: block;
}
#ul li.insta
{
  display: none;
}
footer .container
{
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  align-items: flex-start;


}
#ul-f
{
  display: flex;
  justify-content: center;
  align-items: top;
  
}
#ul-f li{
  display: inline-block;
 list-style: none;
 margin: 0 10px;
 
}
#ul-f li.cnt
{
  display: none;
}
#ul-f li.insta
{
  display: inline-block;
}
#loading
{
  position: absolute;
  z-index: 50;
  top:0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgb(255 255 255/ 55%);
  display: flex;
  justify-content: center;
  align-items: center;
  

}
#loading span{
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  margin: 50px auto;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #2186f2;
  animation: spin 1s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (max-width : 1480px) {
  .hand {
      
      height: 70%;

  }
.logoSp img {

  height: 2.5vw;
}
h2 {
  font-size: 42px;
}
h3 {
  font-size: 21px;

}
body {
 
  font-size: 14px;
}
.title {

  font-size: 22px;

}
h1 {
  font-size: 49px;

}
.l-right
{
  background-color: #edece8;
}
}

@media screen and (max-width : 1300px) {
  .container
{

  max-width: calc(100% - 40px);
}
  .hand {

      
      height: 60%;

  }
  .w300
  {
      width:100%;
      text-align: center;
      
  }


  .v_w300 {
      width: 100%;
  }
}
@media screen and (max-width : 1200px) {
  #logo{
    width: 220px;
  }
  #ul li {

    margin-left: 12px;
}
}
  
   
@media screen and (max-width : 1000px) {
  body {
    font-size: 14x
    
  }
  header
  {
    height: 60px;
  }
  #nav {
    position: fixed;
    top: 0;
    height: calc(100vh);
    background: #edece8;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.7s;
    display: flex;
    justify-content: center;
    align-items: center;

}
#nav.active{
  transform: translateX(0%);
}

#nav-toggle
{
  display: block;
}
#ul
{
  width: 100%;
}
#ul li {
  display: block;
  text-align:center;  
  margin-left: 0
}
#ul li a
{

  font-size: 24px;
    padding: 8px 0;
    display: block;
}
a.contact {
    width: 150px; 
  margin: auto;
}

header .container {

  padding: 0 20px;
}
#logo {

  width: 200px;
  z-index: 50;

}
  .l-left, .l-right {
    width: 100%;
    height: 50%;
}
h1,h2{
  font-size: 36px;

}
h3 {
  font-size: 18px;
  
}
#loyalty
{
  padding-bottom: 0;
}
.hand {

  width: auto;
  height: 100%;

}
.grid_6 ,.grid_3{
  width: 100%;
}

.ptb150 {
  padding: 60px 0;
}
.pt100
{padding-top: 60px;}
.fullm
{
  max-width: 100%;
}
.v_w300 {
  width:100%;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 0 30px 0 ;
}
.control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;

}
.step {

  font-size: 14px;
}

.slider-img {

    width: 100%;
    padding: 15px 30px 15px 30px;
}
.slider-copy {
  width: 100%;
  padding: 20px
}
.block-m
{
  display: block;
}
.exprt {

  max-width: 300px;
  margin: 0 auto 10px auto;
}
.logos {

  flex-wrap: wrap;
}
#container {
  padding: 40px 20px;
}
#popContent
{
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
.b-line {
  font-size: 24px;
  text-align: center;
}
.logoSp {
  padding: 0;
  margin-bottom: 35px;
  width: 50%;
}
.logoSp img {
  height: auto;
  max-height: 40px;
  max-width: 80%;
  margin: auto;
}
.grid_5,.grid_33,.grid_3
{
  width: 100%;
  margin-bottom: 35px;
}
.inputHolder {
  width: 100%;
  margin-bottom: 10px;
}
h2
{
  margin-bottom: 1em;
}
.innerEnq
{
  height: calc(100vh - 100px);
  overflow: scroll;
}
footer .container {
  display: block;

}
#ul-f
{
  display: block;
  padding:30px 0
}
#ul-f li {
  display: block;
  padding: 5px 0;

}
.right-align
{
  text-align: left;
}
.grid_4,.grid_th {
  width: 100%;
  padding: 25px 0;
  max-width: 500px;
  margin: auto;
}
.loaded .svg-holder {

  display: none;
}
#landing
{
  background-color: #2186f2;
}
.royal {

  padding-left: 0;

}
#loyalty
{
  background-color: #fff;
}
.copy-r {
  transform: none;
  padding-top: 10px;
  text-align: left;
  display: block !important;
}

}
  
  
   