.iphone {
    width: 350px;
    height: 606px;
    position: relative;
    transform: rotate(-6deg) skewX(18deg) translateX(20%)
  }
  
  .iphone-camera {
    width: 50%;
    height: 3.5%;
    background-color: #1e1e1d;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    border-radius: 0 0 20px 20px;
  }
  
  .s-camera {
  
    width: 40%;
    height: 24%;
    background-color: #000;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: inset -1px -1px 0 -1px rgb(255 255 255 / 60%);
  
  }
  
  .iphone-screen {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 100%;
    width: 100%;
    background-color: #edece8;
    border-radius: 48px 40px 52px 45px;
    z-index: 5;
    overflow: hidden;
  
  
  
  }
  
  .iphone-inbox {
    padding: 20px;
  }
  
  .back-mail {
    position: relative;
  
  }
  
  .back-mail::before {
    content: '<';
    position: absolute;
    display: block;
    left: -12px;
    top: 0;
  }
  
  .phone-inbox-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    color: #2186f2
  }
  
  .phone-inbox-header-det h3 {
    font-size: 22px;
    padding: 10px 0 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .phone-inbox-main {
    padding-bottom: 12px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 13px;
    z-index: 2;
  }
  
  .phone-inbox-sender {
    width: 40px
  }
  
  .phone-inbox-sender {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    
    background-color: #2186f2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .phone-inbox-sender svg {
    width: 20px;
    height: 20px;
    display: block;
  }
  
  .phone-inbox-email {
    width: calc(100% - 50px);
  }
  
  .phone-inbox-email h4 {
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  
  }
  
  .phone-inbox-email h4 span {
    font-size: 0.7em;
  
  }
  
  .phone-inbox-sub h5 {
    font-size: 16px;
    opacity: 0.8;
    margin-bottom: 0.2em;
  }
  
  .phone-inbox-sub p {
    opacity: 0.7;
    font-size: 14px;
  
  }
  
  .screen-g {
    height: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 14px 18px;
    column-gap: 10px;
    position: relative;
  
  }
  
  .time-g {
    position: absolute;
    left: 22px;
    top: 9px;
    font-size: 11px;
    display: block;
  }
  
  .anthena {
    width: 2px;
    height: 10px;
    background-color: #000;
    display: block;
    position: relative;
  }
  
  .anthena::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 8px;
    background-color: #000;
    left: -4px;
    bottom: 0;
  
  
  }
  
  .anthena::after {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 6px;
    background-color: #000;
    left: -8px;
    bottom: 0;
  
  }
  
  .svg-icon {
    display: block;
    width: 15px;
    height: auto;
  }
  
  .wi-fi {
    width: 13px;
  }
  
  .iphone-front {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    border-radius: 48px 40px 52px 45px;
    box-shadow: -2px 0 0px -1px rgb(255 255 255 / 40%), -1px 3px 3px -3px rgb(255 255 255 / 70%);
    z-index: 2;
  }
  
  .iphone-back {
    z-index: 1;
    position: absolute;
    top: -7px;
    left: -29px;
    height: calc(100% + 40px);
    width: calc(100% + 40px);
    background-image: linear-gradient(to bottom, #122c4a 80%, #1e1e1d);
    border-radius: 74px 40px 84px 50px;
    /* box-shadow: -26px 8px 8px -10px rgb(0 0 0 / 30%), -6px 6px 6px 0 rgb(0 0 0 / 60%), -30px 14px 10px -10px rgb(0 0 0 / 50%), -20px 40px 8px 0 rgb(0 0 0 / 10%), -20px 30px 8px 0 rgb(0 0 0 / 10%), inset 20px 0 15px -6px rgb(0 0 0 / 60%), inset 2px 0 4px -2px rgb(0 0 0 / 60%), inset 2px 0 1px -2px rgb(255 255 255 / 10%), inset 0 -15px 10px -10px rgb(255 255 255 / 15%); */
  }
  
  .iphone-btn {
    display: block;
    position: absolute;
    top: 14%;
    left: 2.5%;
    height: 8%;
    width: 3%;
    background-color: #010101;
    border-radius: 30px;
    transform: skewY(-40deg);
  
  
  }
  
  .btn2 {
    top: 24%;
  
  }
  
  .iphone-btn:before {
    content: "";
    display: block;
    position: absolute;
    height: 77%;
    width: 85%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.9));
    border-radius: 20px;
  }
  
  .iphone-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: -2px;
    top: -1px;
    height: 97%;
    width: 100%;
    background-color: #2d2d2f;
    border-radius: 20px;
    box-shadow: inset -1px -1px 0 -1px rgb(255 255 255 / 60%);
  
  }
  
  .iphone-charger {
    display: block;
    position: absolute;
    bottom: 1.5%;
    left: 4%;
    height: 2%;
    width: 15%;
    background-color: #000;
    border-radius: 18px;
    transform: skewX(-50deg);
    right: 0;
    margin: auto;
  
  }
  
  .iphone-charger::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    /* background-color: #fff; */
    border-radius: 20px 8px 16px 10px;
    box-shadow: 3px 3px 0 -1px rgb(255 255 255 / 40%);
    position: absolute;
    top: -1px;
    left: -1px;
  
  }
  
  .iphone-speaker {
    width: 7px;
    height: 7px;
    transform: skewX(-50deg);
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    bottom: 1.5%;
    left: 37%;
    box-shadow: inset 0 0 7px 0px #000, 2px 1px 0 -1px rgb(255 255 255 / 50%);
  
  }
  
  .blur {
    filter: blur(6px);
  
    opacity: 0.8;
    overflow: hidden;
  }
  
  .phone-inbox-header-det {
    overflow: hidden;
    position: relative;
  }
  
  .iphone-msg {
    position: absolute;
    top: 0;
    left: 100%;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: #edece8;
    z-index: 5;
    transition: opacity 0.5s, left 0.5s;
  
  }
  
  .iphone.animtate2 .iphone-msg {
    opacity: 1;
    left: 0%;
  }
  
  #move-d, .move-top {
    transition: transform 0.5s;
    z-index: 2;
  
  }
  
  .iphone.animtate1 #move-d {
    transform: translateY(110%);
  }
  
  .iphone.animtate1 .move-top {
    transform: translateY(-200%);
  }
  
  .blue-bar {
    background-color: #2186f2;
    padding: 15px;
  
  }
  
  .blue-bar img {
    display: block;
    ;
    width: 50%;
    margin: auto;
  }
  
  .check-c {
    display: block;
    margin: 20px auto;
    width: 50px;
  }
  
  .check-c svg {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .iphone-msg p {
    text-align: center;
    margin-bottom: 1em;
  }
  @media screen and (max-width : 1920px) {
    .iphone {
  
      transform: rotate(-6deg) skewX(18deg) translateX(20%) scale(0.9)
    }
  }
  @media screen and (max-width : 1620px) {
    .iphone {
  
      transform: rotate(-6deg) skewX(18deg) translateX(20%) scale(0.8)
    }
  }
  @media screen and (max-width : 1000px) {
  .iphone {
    transform: rotate(-6deg) skewX(18deg) translateX(0%) scale(0.5);
  }
}